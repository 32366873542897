export const formataData = (data) => { 
    let dataFormatada = new Date(data)
        dataFormatada = dataFormatada.toLocaleDateString('pt-BR', { timeZone: 'UTC' });

    return dataFormatada
}

export const formataTipoSolicitacao = (tipo) => {
    if (tipo === "0"){
      return 'Correção'
    }
    if(tipo === "1"){
      return 'Desenvolvimento'
    }
    if(tipo === "2"){
      return 'Relatório'
    }
    if(tipo === "3"){
      return 'Implantação'
    }
}

export function formataStatus(status) {  
  switch (status) {
      case "0":
          return "PENDENTE";
      
      case "1":
          return "ANDAMENTO";
      
      case "2":
          return "TESTANDO";
      
      case "3":
          return "CONCLUÍDO";
      
      default:
          return "STATUS DESCONHECIDO";
  }
}

export const formataObservacao = (string) => {
   if (string.includes("\n") || string.includes("\r")) {
     const linhas = string.split(/[\n\r]+/);

     const paragrafos = linhas.map((linha, index) => <p key={index}>{linha}</p>);
  
    return <div>{paragrafos}</div>;
    }

      return <p>{string}</p>;
}

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formataHora = (horario) => {
  
  return horario.replace(/\//g, ":");
}

export const inverterDiaMes = (data) => {
    data = data.replace(/\//g, '-');
  
    let [dataPart, horaPart] = data.split(' ');

    let [dia, mes, ano] = dataPart.split('-');
    
    let dataInvertida = `${ano}-${mes}-${dia}`;

    return `${dataInvertida} ${horaPart}`;
}

export const removeTracoData = (data) => {
  let resultado = '';

  for (let i = 0; i < data.length; i++) {
    if (data[i] !== '-') {
      resultado += data[i];
    }
  }

  return resultado
}