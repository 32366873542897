import { useNavigate } from "react-router-dom"

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";


import caminhao from '../../../assets/caminhao.png'

import '../styles.css'

const Transporte = () => {
  const navigate = useNavigate()
  return (
    <div>
       <Header />  
        
        <div className='segment-page'>
          <div className="segment-content">
          <div className='segment-product-title'>
            <img src={caminhao} />
              <span>
                SysTransp - Sistema de Gestão de Transporte
              </span>
          </div>   
            <span className='segment-text'>
              O RodInfo SysTransp automatiza o gerenciamento de maneira simples e organizada de uma empresa de Transporte
              Uma forma eficaz de otimizar seus processos, garantir a qualidade dos processos e melhorar seus resultados.
            </span>

          <div className='buttons'>
            <button className='back-button' onClick={() => navigate('/')}>
              <span>
                página inicial
              </span>
              </button>
          </div> 

          </div>
          
        </div>
        <Footer />
    </div>
  )
};

export default Transporte;
