import axios from "axios";

import { criarToken } from "./baseApi";

const URL_DEPLOY = "https://syshorti.com.br:2083/";

const api = axios.create({
    baseURL: URL_DEPLOY,
    headers: {
        "Content-type": "application/json",
      }
});

api.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem('suporte_token');
      if (!token) {
        const response = await criarToken();
  
        const access_token = response;
  
        localStorage.setItem('suporte_token', access_token);
    }
    config.headers["Authorization"] = ` Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

api.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        localStorage.removeItem('suporte_token');
  
        const response = await criarToken();
  
        const access_token = response;
  
        localStorage.setItem('suporte_token', access_token);
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
        return api(originalRequest);
      }
      return Promise.reject(error);
    }
 );


export default api;



