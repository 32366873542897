import styles from './styles.module.css'

import { verificaEquipe } from '../../utils/validadores'

export default function PageHeader({titulo, total, tituloInput, tipo}){
    
    return (
        <article className={styles.pageHeaderContainer}>
            <div>
                <div className={styles.titulo}>
                    <h1>{titulo}</h1>
                    {verificaEquipe(localStorage.getItem('suporte_usuario')) === true ? (
                        <h2 style={{color: "#0d6efd"}}>RodInfo</h2>
                    ) : 
                    (
                        <h2 style={{color: "#0d6efd"}}>Parceria</h2>
                    )}
                </div>
                <div className={styles.outros}>
                    <span>Usuário: <b>{localStorage.getItem('suporte_usuario')}</b></span>
                    <span>{total}</span>
                </div>
                <span style={{fontWeight: 'bold'}}>{tituloInput}</span>
            </div>
        </article>
    )
}


