import { useNavigate } from "react-router-dom"

import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

import lab from '../../../assets/lab.png'

import '../styles.css'

const Laboratorio = () => {
  const navigate = useNavigate()
  return (
    <div>
       <Header />  
        
        <div className='segment-page'>
          <div className="segment-content">
          <div className='segment-product-title'>
            <img src={lab} />
              <span>
              SysLabor - Sistema de Gestão de Laboratório de Análises Clínicas
              </span>
          </div>   
            <span className='segment-text'>
              O RodInfo SysLabor automatiza o gerenciamento de maneira simples e organizada de um Laboratório de Análises Clínicas, desde a coleta do material até a entrega do resultado.
              Uma forma eficaz de otimizar seus processos, garantir a qualidade dos processos e melhorar seus resultados.
            </span>

          <div className='buttons'>
            <button className='back-button' onClick={() => navigate('/')}>
              <span>
                página inicial
              </span>
              </button>
          </div> 

          </div>
          
        </div>
        <Footer />
    </div>
  )
}

export default Laboratorio