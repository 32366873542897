import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { FaWhatsapp } from 'react-icons/fa'

import sysHorti from '../../assets/sysHorti.png'

import './styles.css'

const Header = () => {
  const [segmentosDropdownOpen, setSegmentosDropdownOpen] = useState(false)
  const [institucionalDropdownOpen, setInstitucionalDropdownOpen] = useState(false)
  const [menuButtonOpen, setMenuButtonOpen] = useState(false)
  const navigate = useNavigate()

  function handleSegmentosDropdown() {
    setSegmentosDropdownOpen(!segmentosDropdownOpen)
  }

  function handleInstitucionalDropdown() {
    setInstitucionalDropdownOpen(!institucionalDropdownOpen)
  }

  function handleMobileMenu() {
    setMenuButtonOpen(!menuButtonOpen)
  }

  return (
    <header className='header-container'>
      <div className='header-logo' onClick={() => navigate('/')}>
        <img src={sysHorti} alt="Rodinfo Logo" />
      </div>
      <div className={`header-items ${menuButtonOpen ? 'open' : ''}`}>
        <div className="dropdown">
          <div className="dropbtn" onClick={handleSegmentosDropdown}>segmentos <MdKeyboardArrowDown size={24} /></div>
          {segmentosDropdownOpen && (
            <div className="dropdown-content">
              <a className='item' onClick={()=>navigate('/segmentos/hortifruti')}>Hortifrutigranjeiro</a>
              <a className='item' onClick={()=>navigate('/segmentos/condominio')}>Condomínios</a>
              <a className='item' onClick={()=>navigate('/segmentos/imobiliaria')}>Imobiliária</a>
              <a className='item' onClick={()=>navigate('/segmentos/laboratorio')}>Análises Laboratoriais</a>
              <a className='item' onClick={()=>navigate('/segmentos/transporte')}>Transportes</a>
              <a className='item' onClick={()=>navigate('/segmentos/varejo')}>Loja de atacado/varejo</a>
              <a className='item' onClick={()=>navigate('/segmentos/industria')}>Indústria</a>
            </div>
          )}
        </div>
        <div className="dropdown">
          <div className="dropbtn" onClick={handleInstitucionalDropdown}>institucional <MdKeyboardArrowDown size={24} /></div>
          {institucionalDropdownOpen && (
            <div className="dropdown-content">
              <a onClick={() => navigate('/empresa')}>a empresa</a>
            </div>
          )}
        </div>
        <div className="dropdown">
          <a className="dropbtn" onClick={() => navigate('/contato')}>contato</a>
        </div>
        <div className="dropdown">
          <a className="dropbtn" target='_blank' onClick={() => navigate('/suporte')}>suporte</a>
        </div>
        <div className="dropdown">
          <a className="dropbtn" target='_blank' href='http://www.rodinfo.com.br/syshorti'>acesso ao cliente</a>
        </div>
      </div>
      <div className="suport-button">
        <a className='button' target="_blank" href='https://wa.me/message/NI2RPDP3X5SJA1'>
          <span>
            <FaWhatsapp size={24} />
            fale com a RodInfo
          </span>
        </a>
      </div>
      <button className="mobile-menu-button" onClick={handleMobileMenu}>
        {menuButtonOpen ? <AiOutlineClose size={32} /> : <AiOutlineMenu size={32} />}
      </button>
    </header>
  )
}
export default Header
