import React from 'react'
import './spinnerLoader.css'

function LoadingSpinner(){

    return (
       <div className='loader-container'>
            <span className="loader"></span>
            <p>Carregando...</p>
       </div>
    )
}

export default LoadingSpinner;