import Header from '../src/components/Header/Header'
import Carousel from './components/Carousel/Carousel';
import Solutions from './components/Solutions/Solutions';
import Footer from '../src/components/Footer/Footer'

import { FaWhatsapp } from 'react-icons/fa'

import bgRodinfo from '../src/assets/bgRodinfo.jpg'

import labicon from '../src/assets/lab.png'

import pc from '../src/assets/pc.svg'
import cart from '../src/assets/cart.png'
import frutas from '../src/assets/frutas.png'
import fabrica from '../src/assets/fabrica.png'
import caminhao from '../src/assets/caminhao.png'
import condominio from '../src/assets/condominio.png'
import casa from '../src/assets/casa.png'

import playstore from '../src/assets/playstore.png'
import applestore from '../src/assets/applestore.png'

import './App.css';

function App() {

  return (
    <div className="app-container">
      <Header />
        <div className='carrosel'>
          <Carousel />
        </div>

        <div className="customers">
          <span className='offers'>A RodInfo acumula há mais de 3 décadas experiência em Tecnologia da Informação que podem ser perfeitos para gerenciar seu negócio.
          </span>
        </div>
        
        <div className='solutions-container'>
        <span>várias soluções para seu negócio, conheça nossos produtos</span>
          <p>Possuimos soluções nos seguintes segmentos de mercado abaixo e também soluções personalizadas para melhor lhe atender.</p> 
          <div className='solutions-list'>
             <Solutions name={"Sistema de Gestão de Hortifruti "} product={"SysHorti"} bgImage={bgRodinfo} icon={frutas} linkTo={'/segmentos/hortifruti'}/>
             <Solutions name={"Sistema de Gestão de Condomínio "} product={"SysCondo"} bgImage={bgRodinfo} icon={condominio} linkTo={'/segmentos/condominio'}/>
             <Solutions name={"Sistema de Gestão de Imobiliária"} product={"SysImobi"} bgImage={bgRodinfo} icon={casa} linkTo={'/segmentos/imobiliaria'}/>
             <Solutions name={"Sistema de Gestão de Laboratório de Análises Clínicas"} product={"SysLabor"} bgImage={bgRodinfo} icon={labicon} linkTo={'/segmentos/laboratorio'} />
             <Solutions name={"Sistema de Gestão de Transportes"} product={"SysTransp"} bgImage={bgRodinfo} icon={caminhao} linkTo={'/segmentos/transporte'}/>
             <Solutions name={"Sistema de Gestão de Loja de Atacado/Varejo"} product={"SysLoja"} bgImage={bgRodinfo} icon={cart} linkTo={'/segmentos/varejo'} />
             <Solutions name={"Sistema de Gestão de Indústria "} product={"SysInd"} bgImage={bgRodinfo} icon={fabrica} linkTo={'/segmentos/industria'}/>
          </div>
          <div className='getInContact-container'>
            <div className='text'>
              <span style={{color: "#FFF", fontSize: "1.85rem", fontWeight: "bold"}}>vamos conversar?</span>
            </div>
            <div  className='text2'>
            <span>Apresente a sua necessidade que iremos analisar e apresentar a melhor solução.</span>
              <a target='_blank' href='https://wa.me/message/NI2RPDP3X5SJA1'>
              <button className='call-us-button'>
                <span>
                <FaWhatsapp size={24} />
                receba uma ligação
                </span>
                </button>
              </a>
          </div>
          </div>
        </div>

        <div className='marketing-one-container'>
          <div className='messages'>
            <span className='marketing-title'>possuímos diversas soluções que podem solucionar os seus problemas.</span>
            <span className='marketing-description'>A RodInfo acumula anos de experiência em soluções de Tecnologia da Informação de vanguarda que podem ser perfeitos para gerenciar sua empresa.</span>
             <a target='_blank' href='https://wa.me/message/NI2RPDP3X5SJA1'>
               <button className='call-us-marketing' >
                <span>
                <FaWhatsapp size={24} />
                  entre em contato
                </span>
               </button>
             </a>
          </div>
          <div className='image-container'>
            <img src={pc}  />
          </div>
        </div>
        <div className='find-us-mobile'>
          <span>Encontre nos nas plataformas mobile!</span>
          <div className='platforms'>
            <a href='https://play.google.com/store/apps/details?id=com.embarcadero.rCEASA' target="_blank">
              <img src={playstore} className='playstore-img' />
            </a>
            <a href='https://apps.apple.com/br/app/r-gest%C3%A3o-de-ceasa/id1619132169' target='_blank'>
              <img src={applestore} className='applestore-img' />
            </a>
          </div>
        </div>
        <Footer />
    </div>
  );
}

export default App;
