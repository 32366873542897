import { useNavigate } from 'react-router-dom'

import './styles.css'


const Solutions = ({name, product, icon, bgImage, linkTo}) => {

  const navigate = useNavigate();
  
  return (
    <div className='solutions'>
      <div className='background'>
        <div className='bg-image'>
          <img src={bgImage} alt="" style={{height: "5rem", width: "100%", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", marginTop:'-12px'}}/>
        </div>
        <hr className='separator' />
      </div>
      <div className='icon-container'>
        <img className='icon' src={icon}/>
      </div>
      <div className='product-name'>
        <span>{product}</span>
        <span>{name}</span>
      </div>
      <div className='checkout-button'>
        <button onClick={()=>navigate(linkTo)}>confira</button>
      </div>
    </div>
  )
}

export default Solutions