import { useEffect, useRef, useState } from "react";

import icones from "../../assets/icons/icones";

import BaseLayout from "../suporte/restrito/layout/BaseLayout";
import  { Dropdown, DropdownTitle, ItemDropdown, ItemSubmenu } from "../../components/Dropdown";

import "./styles.css"

export default function Manuais (){

    const [showOptions, setShowOptions] = useState(false)
    const dropdownRef = useRef(null);


    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowOptions(false);
        }
      };

    function verificaEquipe() {
      const usuario = localStorage.getItem("suporte_usuario");
      return usuario === "andre" || usuario === "samuel" || usuario === "bento" || usuario === "rodimilson";
    }
  
    useEffect(() => {
       document.addEventListener('mousedown', handleClickOutside);
       return () => {
         document.removeEventListener('mousedown', handleClickOutside);
       };
      }, []);

    return (
        <BaseLayout>
            <main className="manuais-content">
                <h1>Manuais</h1>
                <section className='conteudo-manuais'>
                  <div className='lista'>
                    {verificaEquipe() && (
                      <a className="manuais-btn" href="https://www.rodinfo.com.br/util/" target='_blank' rel='noreferrer'>Diversos</a>
                    )}
                    <a className="manuais-btn" href="https://www.rodinfo.com.br/install/" target='_blank' rel='noreferrer'>Instalação ( SysHorti )</a>
                    <a className="manuais-btn" href="https://www.rodinfo.com.br/atualizavs/ceasapr/" target='_blank' rel='noreferrer'>Atualiza Vs ( Ceasa PR )</a>
                    {verificaEquipe() && (
                      <a className="manuais-btn" href="https://www.rodinfo.com.br/atualizavs/ceasasp/" target='_blank' rel='noreferrer'>Atualiza Vs ( Ceasa SP )</a>
                    )}

                    <div className='lista-manuais'>
                        <button className="manuais-btn"  onClick={()=> setShowOptions(!showOptions)}>Manuais</button>
                          {showOptions && (
                            <Dropdown>
                            <ItemDropdown title='Emissão de Notas'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota%20Complementar%20-%20CEASAPR/Manual%20Nota%20Complementar.pdf' download='nota-complementar.pdf'>Nota complementar</ItemSubmenu>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota/Manual%20Nota%20de%20Importa%e7%e3o/Manual%20Nota%20de%20Importa%e7%e3o.pdf' download='nota-importacao.pdf'>Nota de Importação</ItemSubmenu>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota/Manual%20Cancelar%20Nota/Manual%20Cancelar%20Nota.pdf' download='cancelar-nota.pdf'>Cancelar Nota</ItemSubmenu>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota/Manual%20Emitir%20Nota%20CEASAPR/Manual%20Emitir%20Nota.pdf' download='emitir-nota.pdf'>Emitir Nota</ItemSubmenu>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota/Manual%20Gerar%20Carta%20de%20Corre%e7%e3o/' download='gerar-carta-correcao.pdf'>Gerar Carta de Correção</ItemSubmenu>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota/Manual%20Inutiliza%e7%e3o%20de%20Nota/' download='inutilizar-numero.pdf'>Inutilzar Número de NFe</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='Entrada de Notas'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota/Manual%20Lan%e7ar%20NF-e/Manual%20Lan%e7ar%20NF-e.pdf' download='lancar-nfe.pdf'>Lançar NFe</ItemSubmenu>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Nota/Manual%20Lan%e7ar%20NF-e/Manual%20Lan%e7ar%20NF-e.pdf' download='lancar-nfe.pdf'>Importar NFe</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='Emissão MDF-e'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20MDF-e/Manual%20MDF-e.pdf' download='mdfe.pdf'>Lançar MDFe</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='Cobrança'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Cobran%e7a/Manual%20Cobran%e7a%20CEASAPR/MANUAL%20DE%20COBRAN%c7A.pdf' download='cobranca.pdf'>Cobrança</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='Plano de Contas'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Plano%20De%20Conta/Manual%20Plano%20de%20Conta.pdf' download='plano-de-conta.pdf'>Plano de contas</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='SPED'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Atualizados/Manual%20SPED.pdf' download='sped.pdf'>SPED</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='Relatório de Log'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Relatorio%20Log%20Usu%e1rios/Manual%20Relatorio%20Log.pdf' download='relatorio-log.pdf'>Relatório de Log</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='Certificados'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Manual%20Certificado/Manual%20Certificado.pdf' download='manual-certificado.pdf'>Certificado</ItemSubmenu>
                            </ItemDropdown>
                            
                            <ItemDropdown title='Instalações'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Atualizados/POP%20-%20Manual%20SysHorti%201.2.pdf' download='instalacao.pdf'>Instalações</ItemSubmenu>
                            </ItemDropdown>
    
                            <ItemDropdown title='Dashboard'>
                              <ItemSubmenu to='https://www.rodinfo.com.br/manual/Atualizados/Manual%20Web%20-%20DashBoard.pdf' download='dashboard.pdf'>Dashboard</ItemSubmenu>
                            </ItemDropdown>
    
                          </Dropdown>
                          )}

                      </div>

                    </div>
                </section>
            </main>
        </BaseLayout>
    )
}