import React, { useState } from 'react';
import { GoChevronDown, GoChevronUp } from "react-icons/go";


import styles from './styles.module.css';

const Dropdown = ({ children }) => {
  return <div className={styles.dropdown}>{children}</div>;
};

const DropdownTitle = ({ children }) => {
  return <div className={styles.dropdownTitle}>{children}</div>;
};

const ItemDropdown = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <div className={styles.itemDropdown}>
      <div className={styles.itemDropdownTitle} onClick={toggleOpen}>
        {title}
        <span className={`${styles.arrow} ${open ? styles.open : ''}`}>
          {open ? <GoChevronDown size={18}/> : <GoChevronUp size={18}/>}
        </span>
      </div>
      <div className={`${styles.submenu} ${open ? styles.open : ''}`}>
        {children}
      </div>
    </div>
  );
};

const ItemSubmenu = ({ children, to, download }) => {
  return <div className={styles.itemSubmenu}>

    <a href={to} download={download}>
      {children}
    </a>
    
   
    </div>;
};

export { Dropdown, DropdownTitle, ItemDropdown, ItemSubmenu };
