import { useNavigate } from "react-router-dom"

import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'


import fabrica from '../../../assets/fabrica.png'
import '../styles.css'

const Industria = () => {
  const navigate = useNavigate()
  return (
    <div>
       <Header />  
        
        <div className='segment-page'>
          <div className="segment-content">
          <div className='segment-product-title'>
            <img src={fabrica} />
              <span>
              SysInd - Sistema de Gestão de Indústria de Manufatura
              </span>
          </div>   
            <span className='segment-text'>
            O RodInfo SysInd automatiza o gerenciamento de uma indústria de manufatura desde o recebimento da matéria-prima, sua industrialização e entrega.
            Uma forma eficaz de otimizar seus processos, garantir a qualidade dos processos e melhorar seus resultados.
            </span>

          <div className='buttons'>
            <button className='back-button' onClick={() => navigate('/')}>
              <span>
                página inicial
              </span>
              </button>
          </div> 

          </div>
          
        </div>
        <Footer />
    </div>
  )
}

export default Industria