import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFetchProgramas } from "../../hooks/useFetchProgramas";
import { useFetchClientes } from "../../hooks/useFetchClientes";
import DataTable from "react-data-table-component";
import api from "../../api/api";

import Chart from "react-apexcharts";
import { useFetchCategoria } from "../../hooks/useFetchCategoria";
import { useFetchChamadosMes } from "../../hooks/useFetchChamadosMes";

import BaseLayout from "../suporte/restrito/layout/BaseLayout";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";

import icones from "../../assets/icons/icones";

import './styles.css'

export default function Recorrencias(){
   const navigate = useNavigate()

   const [tipoProblema, setTipoProblema] = useState('')
   const [rCliente, setRCliente] = useState('')

   const [recorrencias, setRecorrencias] = useState([])

   const [loading, setLoading] = useState(true)

   const [erro, setErro] = useState({
      erro: false,
      message: ""
    })


    const fetchClientes = useFetchClientes()
    const fetchProgramas = useFetchProgramas()


    useEffect(()=> {
      const BuscarRecorrencias = () => {
        setLoading(true);
  
        let busca = {
          codi_pesso: Number(rCliente),
          chav_chama: tipoProblema.split('-')[0]
        }
  
        try {
          api.post('/consulta/recorrencia', busca)
          .then((res) => {
              setRecorrencias(res.data)
          })
        } catch (error) {
          setErro({
            erro: true,
            message: "Nenhuma recorrência encontrada."
          })
        }
        finally{
          setLoading(false)
        }
      } 

      BuscarRecorrencias()


    },[rCliente, tipoProblema])


    const recorrenciaStylesTable = {
      rows: {
        style: {
          minHeight: '50px',
        },
      },
      headCells: {
        style: {
          paddingLeft: '8px', 
          paddingRight: '8px',
          fontWeight: 'bold',
          fontSize: '16px'
        },
      },
      cells: {
        style: {
          paddingLeft: '8px', 
          paddingRight: '8px',
          fontSize: '20px',
        },
      },
    };


    const colunasrecorrencias = [
      {
        name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}><icones.FiUser/>Cliente</span>,
        selector: row => row.nome_pesso,
        width: '250px'
      },
      {
        name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiCalendar/>Data</span>,
        selector: row => row.data,
        width: '200px'
      },
      {
        name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.PiPuzzlePiece />Módulo</span>,
        selector: row => row.chav_chama,
        width: '150px'
      },
      {
        name: <span style={{display: 'flex', gap: '.25rem', alignItems: 'center'}}><icones.FiInfo/>Descrição</span>,
        selector: row => row.problema,
        width: '500px'
      },
    ];

    let recorrenciasFiltradas = []

    if(recorrencias && recorrencias.dados && recorrencias.dados.length > 0) {
      recorrenciasFiltradas = recorrencias.dados.map((reinc)=> ({
          nome_pesso: reinc.nome_pesso,
          codi_pesso: reinc.codi_pesso,
          data: reinc.data_chama,
          contato: reinc.nome_conta,
          problema: reinc.desc_probl,
          chav_chama: reinc.chav_chama,
        }))
    }

    const fetchCategorias = useFetchCategoria(rCliente, tipoProblema.split(' - ')[0])
    let listaCategorias = fetchCategorias.categoria ? fetchCategorias.categoria : [];

    let porcentageRecorrencias = {
      series: listaCategorias.map(item => parseInt(item.total)),
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: listaCategorias.map(item => item.chav_chama),
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex] + "";
          }
        },
        responsive: [{
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };


    const fetchChamadosMes = useFetchChamadosMes()
    let listaChamadosMes = fetchChamadosMes.chamado ? fetchChamadosMes.chamado.map((x) => parseInt(x.qtde_chama)) : [];

    let acompanhamento = {    
      series: [{
        name: 'Chamados',
        data: listaChamadosMes
      }],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          },
          plotOptions: {
              bar: {
                borderRadius: 10,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: true,
              offsetY: 20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            
            xaxis: {
              categories: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
              position: 'top',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              tooltip: {
                enabled: true,
              }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
              }
            
            },
          },          
    };

    let listaProgramas = fetchProgramas.programa ? fetchProgramas.programa.map((x) => x) : [];
    let listaClientes = fetchClientes.cliente ? fetchClientes.cliente : [];
    
    return (
      <BaseLayout>
        <main className="recorrencias-content">

          <div className="titulo-recorrencias">
             <h1>Lista de recorrências</h1>
             <span>
              <p>* As recorrências são baseadas em registros dos últimos 30 dias.</p>
             </span>
          </div>

          {/* inicio do bloco de filtros */}
          <div className="filtros-recorrencias">
            <select className='input' value={rCliente} onChange={(e)=> setRCliente(e.target.value)}>
              <option value="">Todos</option>
                {listaClientes.map((cliente)=> (
              <option key={cliente.codi_pesso} value={cliente.codi_pesso}>{cliente.nome_pesso}</option>
               ))}
            </select>

            <div>
              <select className='input' value={tipoProblema} onChange={(e) => setTipoProblema(e.target.value)}>
                <option value="">Todos</option>
                  {listaProgramas.map((x, index)=> (
                <option key={index} value={`${x.desc_progr}-${x.nome_progr}`}>{x.desc_progr} - {x.nome_progr}</option>
                   ))}
                <option value="AlumiPortas">AlumiPortas</option>
                <option value="Indústria">Indústria</option>
                <option value="Biovida">Biovida</option>  
                <option value="Aplicativo">Aplicativo</option>
                <option value="Permissões">Permissões</option>
                <option value="Dúvidas">Dúvidas</option>
                <option value="Certificado">Instalação certificado</option>
                <option value="Mapeamento e conexão">Mapeamento e conexão</option>
                <option value="Instalação de impressoras">Impressoras</option>
                <option value="Relatórios">Relatórios</option>
                <option value="Recibos">Recibos</option>
                <option value="Outros">Outros</option>
                <option value="Implantação">Implantação</option>
                <option value="Terminal">Terminal</option>
              </select>
            </div>

          </div>
          {/* fim do bloco de filtros */}
         
          {erro.erro && (
            <div className="erro-container">
              <p>{erro.message}</p>
            </div>
          )}

          <section className="lista-recorrencias">
            <DataTable
              columns={colunasrecorrencias}
              data={recorrenciasFiltradas} 
              customStyles={recorrenciaStylesTable}
              noDataComponent={
              <div style={{marginTop: "1rem"}}>
                <span>Nenhuma recorrência encontrada.</span>
              </div>
              }
              responsive
              striped
            />
          </section>

          <section className="graficos-recorrencias">
            <div className='grafico'>
              <h2>Composição geral de recorrências</h2>
                <Chart
                  type='pie'
                  options={porcentageRecorrencias.options}
                  series={porcentageRecorrencias.series}
                  height={300}
                  style={{width: "100%"}}
                />  
            </div>

            <div className='grafico'>
              <h2>Progressão de chamados</h2>
                <Chart
                  type='bar'
                  options={acompanhamento.options}
                  series={acompanhamento.series}
                  height={300}
                  style={{width: "100%"}}
                />  
             </div>
          </section>

        </main>     
      </BaseLayout>
    )
}