import shopping from '../../../assets/shopping.jpeg'
import time from '../../../assets/time.svg'
import code from '../../../assets/code.svg'
import dashboard from '../../../assets/dashboard.svg'

import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'


import './sobre.css'


const Sobre = () => {
  return (
    <div>
    <Header />  

      <div className='about-page'>
        <div className="about-container-first">
          <div className='texts'>
            <span className='principal-text'>a empresa</span>
            <span className='text-description'>A RodInfo nasceu em 1991 com um grande desafio de  prestar serviços de desenvolvimento e consultoria em tecnologia da informação. Para atingir esse objetivo, além do seu conhecimento teórico, a RodInfo também contou com a sua expertise técnica diferenciada e com a colaboração de seus clientes e colaboradores.</span>
            <span className='text-description'>Desde então, a RodInfo tem construído uma história de sucesso com soluções inovadoras de alto valor agregado e aderente às necessidades dos seus clientes.</span>
          </div>
              <div className="about-image">
                <img src={shopping} alt="" />
              </div>
        </div>
        <div className="about-container">
        <div className="about-image">
              <img src={time} alt="" />
            </div>
          <div className='texts'>
            <span className='principal-text'>capital intelectual</span>
            <span className='text-description'>A RodInfo conta com um quadro de colaboradores técnicos extremamente qualificado, que inclui profissionais com graduação e pós-graduação.</span>
            <span className='text-description'></span>
          </div>
        </div> 

        <div className="about-container-2">
          <div className='texts'>
            <span className='principal-text'>tecnologia própria</span>
            <span className='text-description'>A RodInfo  é uma empresa detentora de tecnologia própria, que reúne condições para transformar problemas complexos do mercado em soluções simples, objetivas e fáceis de usar.</span>
          </div>
          <div className="about-image">
              <img src={code} alt="" />
            </div>
        </div> 

        <div className="about-container">
          <div className="about-image">
              <img src={dashboard} alt="" />
            </div>
          <div className='texts'>
            <span className='principal-text'>gerenciamento de projetos</span>
            <span className='text-description'>A metodologia utilizada pela RodInfo para o gerenciamento, controle e realização de projetos, é o resultado de estudos aprofundados no PMI (Project Management Institute), que foi a base para construção dos padrões e formatos de documentação de nossa metodologia.</span>
          </div>  
        </div>  
      </div> 
     <Footer />
 </div>
  )
}

export default Sobre