import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Show } from '../../../../../components/Show/Show';

import icones from "../../../../../assets/icons/icones";
import logoMaca from "../../../../../assets/iconemaca.jpg"; 

import './styles.css';

export default function Menu() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    
    const isActive = (path) => location.pathname === path ? 'active' : '';

    function verificaEquipe() {
        const usuario = localStorage.getItem("suporte_usuario");
        return usuario === "andre" || usuario === "samuel" || usuario === "bento" || usuario === "rodimilson";
    }
    
    return (
        <aside className={openDrawer ? "menu-drawer" : "retracted-menu-drawer"}>
            
            <div className="menu-title" onClick={() => setOpenDrawer(!openDrawer)}>
                <Show>
                    <Show.When isTrue={openDrawer}>
                        <div className="item" onClick={() => setOpenDrawer(!openDrawer)}>
                            <img src={logoMaca} style={{ width: "45px", height: "45px" }} /><span>Menu</span>
                        </div>
                    </Show.When> 
                    <Show.Else>
                        <div className="item" onClick={() => setOpenDrawer(!openDrawer)}>
                            <img src={logoMaca} style={{ width: "45px", height: "45px" }} />
                        </div>
                    </Show.Else>
                </Show>
            </div>
            
            <div className="divider"></div>

            <div className="menu-items">
                
                 <div onClick={() => navigate('/restrito/clientes')} className={isActive('/restrito/clientes')}>
                        <Show>
                            <Show.When isTrue={openDrawer}>
                                <div className="item">
                                    <icones.FiSearch size={24} /><span>Clientes</span>
                                </div>
                            </Show.When> 
                            <Show.Else>
                                <div className="item">
                                    <icones.FiSearch size={24} />
                                </div>
                            </Show.Else>
                        </Show>
                    </div>
                
                <div onClick={() => navigate('/restrito/chamados')} className={isActive('/restrito/chamados')}>
                    <Show>
                        <Show.When isTrue={openDrawer}>
                            <div className="item">
                                <icones.RiCustomerService2Fill size={24}/><span>Chamados</span>
                            </div>
                        </Show.When> 
                        <Show.Else>
                            <div className="item">
                                <icones.RiCustomerService2Fill size={24}/>
                            </div>
                        </Show.Else>
                    </Show>
                </div>

                
                  <div onClick={() => navigate('/restrito/tarefas')} className={isActive('/restrito/tarefas')}>
                    <Show>
                      <Show.When isTrue={openDrawer}>
                        <div className="item">
                          <icones.FiCheckSquare size={24} /><span>Tarefas</span>
                        </div>
                        </Show.When> 
                        <Show.Else>
                            <div className="item">
                                <icones.FiCheckSquare size={24} />
                            </div>
                        </Show.Else>
                    </Show>
                </div>

                {verificaEquipe() && 
                <div onClick={() => navigate('/restrito/recorrencias')} className={isActive('/restrito/recorrencias')}>
                    <Show>
                        <Show.When isTrue={openDrawer}>
                            <div className="item">
                                <icones.GoGraph size={24} /><span>Recorrências</span>
                            </div>
                        </Show.When> 
                        <Show.Else>
                            <div className="item">
                                <icones.GoGraph size={24} />
                            </div>
                        </Show.Else>
                    </Show>
                </div>
                }
                <div onClick={() => navigate('/restrito/manuais')} className={isActive('/restrito/manuais')}>
                    <Show>
                        <Show.When isTrue={openDrawer}>
                            <div className="item">
                                <icones.FiHelpCircle size={24} /><span>Manuais</span>
                            </div>
                        </Show.When> 
                        <Show.Else>
                            <div className="item">
                                <icones.FiHelpCircle size={24} />
                            </div>
                        </Show.Else>
                    </Show>
                </div>
                
                {verificaEquipe() && (
                    <div onClick={() => navigate('/restrito/monitoramento')} className={isActive('/restrito/monitoramento')}>
                     <Show>
                        <Show.When isTrue={openDrawer}>
                            <div className="item">
                                <icones.RiSpyFill size={24} /><span>Monitoramento</span>
                            </div>
                        </Show.When> 

                        <Show.Else>
                            <div className="item">
                                <icones.RiSpyFill size={24} />
                            </div>
                        </Show.Else>
                     </Show>
                    </div>
                )}

                {verificaEquipe() && (
                    <div onClick={() => navigate('/restrito/relatorio')} className={isActive('/restrito/relatorio')}>
                     <Show>
                        <Show.When isTrue={openDrawer}>
                            <div className="item">
                                <icones.TbReportSearch size={24} /><span>Relatórios</span>
                            </div>
                        </Show.When> 

                        <Show.Else>
                            <div className="item">
                                <icones.TbReportSearch size={24} />
                            </div>
                        </Show.Else>
                     </Show>
                    </div>
                )}

                <div onClick={() => navigate('/suporte')} className={isActive('/suporte')}>
                     <Show>
                        <Show.When isTrue={openDrawer}>
                            <div className="item">
                                <icones.RiLogoutBoxLine size={28} /><span>Sair</span>
                            </div>
                        </Show.When> 

                        <Show.Else>
                            <div className="item">
                                <icones.RiLogoutBoxLine size={28} />
                            </div>
                        </Show.Else>
                     </Show>
                    </div>
                <div>

              </div>
            </div>
        </aside>
    );
}
