import { useState, useRef, useEffect } from "react";
import api from '../../api/api'

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useDownloadExcel } from 'react-export-table-to-excel'

import { Input } from "../../components/Input";
import BaseLayout from "../suporte/restrito/layout/BaseLayout";
import CustomButton from "../../components/ui/Button"
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner'


import { formataData, getCurrentDate, removeTracoData } from '../../utils/formatadores'
import { data_dia_primeiro } from '../../utils/dataAtual'

import icones from '../../assets/icons/icones'
import styles from './styles.module.css'
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { verificaEquipe } from '../../utils/validadores'

export default function Relatorios(){
    const tableRef = useRef(null)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    
    const [dataInicial, setDataInicial] = useState(data_dia_primeiro())
    const [dataFinall, setDataFinall] = useState(getCurrentDate())
        
    const [login, setLogin] = useState('')

    const [listaClientes, setListaClientes] = useState([])
    const [clienteSelecionado, setClienteSelecionado] = useState('')
    const [nomeClienteSelecionado, setNomeClienteSelecionado] = useState('')


    const [programa, setPrograma] = useState([]);
    const [programaSelecionado, setProgramaSelecionado] = useState('')
    const [tipoSuporte, setTipoSuporte] = useState('')

    const [dados, setDados] = useState([])
    const [todosDados, setTodosDados] = useState([])


    const [qtdRegistrada, setQtdRegistrada] = useState([]);
    const [qtdFinalizada, setQtdFinalziada] = useState([])

    const [pagina, setPagina] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);
    const [itensPorPagina, setItensPorPagina] = useState(10);
    

    useEffect(()=>{
        async function fetchClientes(){
            const response = await api.get(`/consulta/cliente/*|${localStorage.getItem("suporte_usuario")}`);
            setListaClientes(response.data.dados)
        }
        async function fetchProgramas(){
            const response = await api.get(`/consulta/programas`);
            setPrograma(response.data.dados);
        }
        fetchProgramas()
        fetchClientes()

        if(!verificaEquipe(localStorage.getItem("suporte_usuario"))){
            navigate('/suporte')
        }
    },[])

    useEffect(() => {
        setIsDisabled(dados.length === 0);
    },[dados]); 
    
    useEffect(()=> {
        informacoesParaRelatorio()
    },[login, programaSelecionado, clienteSelecionado, dataInicial, dataFinall, tipoSuporte])

    
    function alteraItensPorPagina(e){
        const item = e.target.value
        setItensPorPagina(item)
    }

    function carregaPaginaSeguinte() {
        if (pagina < totalPaginas) {
            setPagina(prevPagina => prevPagina + 1);
        }
    }
    
    function carregaPaginaAnterior() {
        if (pagina > 1) {
            setPagina(prevPagina => prevPagina - 1);
        }
    }

    function carregaPrimeiraPagina() {
        if (pagina > 1) {
            setPagina(1);
        }
    }

    function carregaUltimaPagina() {
        if (pagina < totalPaginas) {
            setPagina(totalPaginas);
        }
    }

    useEffect(() => {
        buscarInformacoes();
    }, [pagina]);

    useEffect(() => {
        setPagina(1);
        buscarInformacoes();
    }, [itensPorPagina]);


    async function informacoesParaRelatorio(){        
            if (!dataInicial || !dataFinall) {
                alert("Preencher as datas inicial e final.");
                setLoading(false);
                return;
            }
        
            let info = {
                data_inici: formataData(dataInicial),
                data_final: formataData(dataFinall),
                login: localStorage.getItem('suporte_usuario'),
                nome_progr: programaSelecionado,
                codi_pesso: clienteSelecionado,
                usuario: login,
                tipo_supor: tipoSuporte,
                pagina: pagina,
                take: 0
            };
        
            try {
                const response = await api.post('/relatorio/chamado', info);
                setTodosDados(response.data.relatorio);
                setDisableButton(false)
                setQtdRegistrada(response.data.qtde_chamado_criado);
                setQtdFinalziada(response.data.qtde_chamado_finalizados);

            } catch (error) {
                console.error(error);
                setTodosDados([]);
                if (error.response && error.response.status === 404) {
                    setDisableButton(true)
                }
            } finally {
                setLoading(false);
            }
    }
    
    async function buscarInformacoes(e) {
        if (e) e.preventDefault();
        setLoading(true);
    
        if (!dataInicial || !dataFinall) {
            alert("Preencher as datas inicial e final.");
            setLoading(false);
            return;
        }
    
        let info = {
            data_inici: formataData(dataInicial),
            data_final: formataData(dataFinall),
            login: localStorage.getItem('suporte_usuario'),
            nome_progr: programaSelecionado,
            codi_pesso: clienteSelecionado,
            usuario: login,
            tipo_supor: tipoSuporte,
            pagina: pagina,
            take: itensPorPagina
        };
        
        try {
            const response = await api.post('/relatorio/chamado', info);
            setDados(response.data.relatorio);
            setQtdRegistrada(response.data.qtde_chamado_criado);
            setQtdFinalziada(response.data.qtde_chamado_finalizados);
            setTotalPaginas(response.data.relatorio[0].total_pagina);
            
        } catch (error) {
            console.error(error);
            setDados([]);
            if (error.response && error.response.status === 404) {
                toast.error('Nenhum registro encontrado.');
            }
        } finally {
            setLoading(false);
        }
    }
 
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: `${login === "" ? 'Todos' : login}_${removeTracoData(dataInicial)}_${removeTracoData(dataFinall)}`,
        sheet: 'Relatório',
    }) 
    
    async function gerarPDFGeral(e) {
        e.preventDefault();    
        await informacoesParaRelatorio();
    
        const doc = new jsPDF();
        const hora = new Date().toLocaleTimeString();

        function verificaConteudos() {
            if (nomeClienteSelecionado || login || programaSelecionado || tipoSuporte) {
                return { top: 38 };
            } else {
                return { top: 20 };
            }
        }
    
        function adicionarCabecalho(titulo) {
            doc.setFont('Courier', 'normal');
            doc.setFontSize(12);
            doc.text('RodInfo - Soluções em Tecnologia da Informação', 10, 5);
    
            doc.setFontSize(9);
            doc.text(`${localStorage.getItem("suporte_usuario")} - `, 138, 5);
            doc.text(` Data: ${formataData(getCurrentDate())} ${hora.substring(0, 5)}`, 155, 5);
    
            doc.setFontSize(14);
            doc.text(titulo, 10, 11);
    
            doc.setFontSize(12);
            doc.text('----------------------------------------------------------------------------------------------', 0, 14);
            doc.text(`Período: ${formataData(dataInicial)} a ${formataData(dataFinall)}`, 10, 18);
    

            const filtros = [
                { label: `Usuário: ${login}`, value: login },
                { label: `Programa: ${programaSelecionado}`, value: programaSelecionado },
                { label: `Cliente: ${nomeClienteSelecionado}`, value: clienteSelecionado },
                { label: `Tipo: ${formataTipoUsuario(tipoSuporte)}`, value: tipoSuporte }
            ];
    
            let yOffset = 24;
            filtros.forEach(filtro => {
                if (filtro.value) {
                    doc.setFontSize(12);
                    doc.text(filtro.label, 10, yOffset);
                    yOffset += 4;
                }
            });
        }
    
        doc.setFontSize(8);
        const dadosUsuario = combinarDados(qtdRegistrada, qtdFinalizada, login.toLowerCase());
        const dadosGerais = todosDados.map(item => ({
            ...item,
            tipo_supor: formataTipoUsuario(item.tipo_supor),
            data_chama: formataData(item.data_chama)
        }));


        adicionarCabecalho('Consulta de Chamados Geral');
        doc.autoTable({
            columns: [
                { header: 'Data', dataKey: 'data_chama' },
                { header: 'Login', dataKey: 'login' },
                { header: 'Cliente', dataKey: 'nome_pesso' },
                { header: 'Módulo', dataKey: 'nome_progr' },
                { header: 'Tipo', dataKey: 'tipo_supor' },
            ],
            headStyles: { fillColor: [8, 100, 253] },
            body: dadosGerais,
            margin: verificaConteudos(),
            didDrawPage: () => adicionarCabecalho('Consulta de Chamados Geral')
        });

        const totalChamadosFinalizados = dadosUsuario.reduce((acumulador, item) => {
            acumulador.total += item.chamado_finalizado;
            return acumulador;
        }, { total: 0 });
        
        const totalChamadosRegistrados = dadosUsuario.reduce((acumulador, item) => {
            acumulador.total += item.total;
            return acumulador;
        }, { total: 0 });
        
        const bodyRegistradosFinalizados = [
            { tipo: 'Total Registrados', total: totalChamadosRegistrados.total },
            { tipo: 'Total Finalizados', total: totalChamadosFinalizados.total },
        ];
        
        doc.autoTable({
            columns: [
                { header: 'Tipo', dataKey: 'tipo' },
                { header: 'Total', dataKey: 'total' },
            ],
            headStyles: { fillColor: [8, 100, 253] },
            body: bodyRegistradosFinalizados,
            margin: verificaConteudos(),
        });
       
    
        doc.addPage();
        adicionarCabecalho('Consulta de Clientes'); 
        const dadosClientes = contarOcorrencias(todosDados, 'nome_pesso');
        doc.autoTable({
            columns: [
                { header: 'Posição', dataKey: 'posicao' },
                { header: 'Nome Fantasia', dataKey: 'nome' },
                { header: 'Total de Ocorrências', dataKey: 'total' },
            ],
            headStyles: { fillColor: [8, 100, 253] },
            body: dadosClientes,
            margin: verificaConteudos(),
            didDrawPage: () => adicionarCabecalho('Consulta de Clientes')
        });

        const totalClientes = dadosClientes.reduce((acumulador, item) => {
            acumulador.total += item.total;
            return acumulador;
        }, { total: 0 });

        const bodyTotalClientes = [
            { tipo: 'Total Registrados', total: totalClientes.total },
        ];

        doc.autoTable({
            columns: [
                { header: 'Total de Ocorrências', dataKey: 'total' },
            ],
            headStyles: { fillColor: [8, 100, 253] },
            body: bodyTotalClientes,
            margin: verificaConteudos(),
        });
    
        doc.addPage();
        adicionarCabecalho('Consulta de Usuários');
        
        doc.autoTable({
            columns: [
                { header: 'Login', dataKey: 'nome' },
                { header: 'Total de Registros', dataKey: 'total' },
                { header: 'Total de Finalizados', dataKey: 'chamado_finalizado' }
            ],
            headStyles: { fillColor: [8, 100, 253] },
            body: dadosUsuario,
            margin: verificaConteudos(),
            didDrawPage: () => adicionarCabecalho('Consulta de Usuários')
        });
    
        doc.save(`${login === "" ? 'Todos' : login}_${removeTracoData(dataInicial)}_${removeTracoData(dataFinall)}`);
    }
     
    function formataTipoUsuario(tipo){
        return tipo === "2" ? "Parceria" : "Rodinfo"
    }

    function contarOcorrencias(relatorio, chave) {
        const contagem = {};
    
        for (let i = 0; i < relatorio.length; i++) {
            const registro = relatorio[i]; 
            const valor = registro[chave]; 
    
            if (contagem[valor]) {
                contagem[valor] += 1;
            } else {
                contagem[valor] = 1;
            }
        }

        const resultado = [];
        
        for (let nome in contagem) {
            resultado.push({ nome: nome, total: contagem[nome] });
        }
    
        resultado.sort((a, b) => b.total - a.total);

        const resultadoComIndex = resultado.map((item, index) => ({
            posicao: index + 1 + 'º',
            nome: item.nome,
            total: item.total
        }));

        return resultadoComIndex;
    }
    
    function carregaInformacaoCliente(e) {
        const selectedOption = listaClientes.find(
            (cliente) => cliente.codi_pesso === e.target.value
        );
        
        if (selectedOption) {
            setClienteSelecionado(selectedOption.codi_pesso);
            setNomeClienteSelecionado(selectedOption.nome_fanta.toUpperCase());
        }else{
            setClienteSelecionado('');
            setNomeClienteSelecionado('');
        }
    }

    function combinarDados(qtdRegistrada, qtdFinalizada, usuarioBuscado) {
        const dadosCombinados = {};
        
    
        qtdRegistrada.forEach(({ login, chamados_criados }) => {
            if (!usuarioBuscado || login === usuarioBuscado) {
                dadosCombinados[login] = { total: parseInt(chamados_criados), chamado_finalizado: 0 };
            }
        });
    
        qtdFinalizada.forEach(({ nome_final, chamados_finalizados }) => {
            if (!usuarioBuscado || nome_final === usuarioBuscado) {
                if (dadosCombinados[nome_final]) {
                    dadosCombinados[nome_final].chamado_finalizado = parseInt(chamados_finalizados);
                }
            }
        });
    
        const resultado = Object.entries(dadosCombinados).map(([login, { total, chamado_finalizado }]) => ({
            nome: login,
            total,
            chamado_finalizado
        }));
    
        return resultado.sort((a, b) => b.total - a.total);
    }
 

    return (
        <BaseLayout>
            <main className={styles.relatorioContent}>
                <h2>Consulta de relatórios</h2>
                
                <form className={styles.filtros}>

                    <div className={styles.dataContainer}>
                        <div>
                            <label htmlFor="">Data inicial</label>
                            <input type="date" className={styles.input} value={dataInicial} onChange={(e) => setDataInicial(e.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="">Data final</label>
                            <input type="date" className={styles.input} value={dataFinall} onChange={(e) => setDataFinall(e.target.value)}/>
                        </div>
                    </div>

                    <div style={{width: "100%"}}>
                        <label htmlFor="" style={{fontSize: '20px'}}>Usuário</label>
                        <select className="input" value={login} onChange={(e)=>setLogin(e.target.value)}>
                            <option value="">Todos</option>
                            <option value="Adriano">Adriano</option>
                            <option value="Andre">André</option>
                            <option value="Bento">Bento</option>
                            <option value="Dasniel">Dasniel</option>
                            <option value="Didio">Didio</option>
                            <option value="Rodimilson">Rodimilson</option>
                            <option value="Samuel">Samuel</option>
                            <option value="Victor">Victor</option>
                        </select>
                    </div>

                    <div style={{ width: "100%" }}>
                        <label htmlFor="" style={{ fontSize: '20px' }}>Cliente</label>
                        <select className="input" value={clienteSelecionado} onChange={(e) => carregaInformacaoCliente(e)}>
                            <option value="">Todos</option>
                            {listaClientes.map((item, index) => (
                                <option key={index} value={item.codi_pesso}>
                                    {item.nome_fanta.toUpperCase()}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{width: "100%"}}>
                        <label htmlFor="" style={{fontSize: '20px'}}>Módulo</label>
                        <select className="input" value={programaSelecionado} onChange={(e) => setProgramaSelecionado(e.target.value)}>
                        <option value="">Todos</option>
                            {programa.map((item, index)=> (
                                <option key={index} value={item.desc_progr}>{item.desc_progr}</option>
                            ))}
                                <option value="Dúvidas">Dúvidas</option>
                                <option value="Aplicativo">Aplicativo</option>
                                <option value="Certificado">Instalação certificado</option>
                                <option value="Mapeamento e conexão">Mapeamento e conexão</option>
                                <option value="Instalação de impressoras">Impressoras</option>
                                <option value="Outros">Outros</option>
                                <option value="Implantação">Implantação</option>
                        </select>
                    </div>

                    <div style={{width: "100%"}}>
                        <label htmlFor="" style={{fontSize: '20px'}}>Tipo Suporte</label>
                        <select className="input" value={tipoSuporte} onChange={(e) => setTipoSuporte(e.target.value)}>
                            <option value="">Todos</option>
                            <option value="0">RodInfo</option>
                            <option value="2">Parceria</option>
                        </select>
                    </div>

                    <div style={{width: "100%"}}>
                        <label htmlFor="" style={{fontSize: '20px'}}>Itens por página</label>
                        <select className="input" value={itensPorPagina} onChange={alteraItensPorPagina}>
                            <option value={0}>Todos</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>

                    <div className={styles.actions}>
                        <CustomButton texto={<icones.FiSearch size={24}/>} onClick={buscarInformacoes}/>
                        <CustomButton isDisabled={disableButton} texto={<icones.FaRegFilePdf size={24}/>} onClick={(e) => gerarPDFGeral(e)}/>
                        <CustomButton isDisabled={isDisabled} texto={<icones.RiFileExcel2Line size={24}/>} onClick={onDownload}/>
                    </div>
                </form>

                <section className={styles.tableSection}>
                {loading && (
                    <div>
                        <LoadingSpinner />
                    </div>
                )}
                <table className={styles.table} ref={tableRef}>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Login</th>
                            <th>Cliente</th>
                            <th>Contato</th>
                            <th>Módulo</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                    {dados.length === 0 && (
                    <span>
                        <p>Nenhum registro encontrado.</p>
                    </span>
                    )}
                        {dados.map((item, index) => (
                            <tr key={index} className={styles.tableRow}>
                                <td>{formataData(item.data_chama)}</td>
                                <td>{item.login}</td>
                                <td>{item.nome_pesso}</td>
                                <td>{item.nome_conta}</td>
                                <td>{item.nome_progr}</td>
                                <td>{formataTipoUsuario(item.tipo_supor)}</td>
                            </tr>
                        ))} 
                    </tbody>
                    <tfoot className={styles.footer}>
                        <tr>
                            <td colSpan={6} styles={{padding: '2px'}}>
                                <div className={styles.divFooter}>
                                    <button disabled={pagina === 1} className={styles.paginateButton} onClick={carregaPrimeiraPagina}>
                                        <icones.FiChevronsLeft size={24} color="#000"/>
                                    </button>
                                    <button disabled={pagina === 1} className={styles.paginateButton} onClick={carregaPaginaAnterior}>
                                        <icones.MdOutlineChevronLeft size={24} color="#000"/>
                                    </button>
                                    <span>
                                        Página {pagina} de {totalPaginas}
                                    </span>
                                    <button disabled={pagina === totalPaginas} className={styles.paginateButton}  onClick={carregaPaginaSeguinte}>
                                        <icones.MdOutlineChevronRight size={24} color="#000"/>
                                    </button>
                                    <button disabled={pagina === totalPaginas} className={styles.paginateButton}  onClick={carregaUltimaPagina}>
                                        <icones.FiChevronsRight size={24} color="#000"/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                </table>
                </section>
            </main>
        </BaseLayout>
    )
}