import axios from "axios";

const URL_DEPLOY = "https://syshorti.com.br:2083/";

const api = axios.create({
    baseURL: URL_DEPLOY,
    headers: {
        "Content-type": "application/json",
      }
});

export const criarToken = async () => {
  try {
      const response = await api.post('/auth/criar');
      return response.data.token;
  } catch (error) {
      console.error('Erro ao tentar criar o token:', error);
      throw error;
  }
};

export default api;