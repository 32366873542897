import { useNavigate } from "react-router-dom"

import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

import cart from '../../../assets/cart.png'

import '../styles.css'

const Varejo = () => {
  const navigate = useNavigate()
  return (
    <div>
       <Header />  
        
        <div className='segment-page'>
          <div className="segment-content">
          <div className='segment-product-title'>
            <img src={cart} />
              <span>
              SysInd - Sistema de Gestão de Indústria de Manufatura
              </span>
          </div>   
            <span className='segment-text'>
              O RodInfo SysLoja automatiza o gerenciamento de maneira simples e organizada de uma Loja seja no atacado ou varejo.
              Uma forma eficaz de otimizar seus processos, garantir a qualidade dos processos e melhorar seus resultados.
            </span>

          <div className='buttons'>
            <button className='back-button' onClick={() => navigate('/')}>
              <span>
                página inicial
              </span>
              </button>
          </div> 

          </div>
          
        </div>
        <Footer />
    </div>
  )
}

export default Varejo