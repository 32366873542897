import { useNavigate } from "react-router-dom"

import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Footer"

import condominio from '../../../assets/condominio.png'

import '../styles.css'

const Condominio = () => {

  const navigate = useNavigate()
  return (
    <div>
       <Header />  
        
        <div className='segment-page'>
          <div className="segment-content">
          <div className='segment-product-title'>
            <img src={condominio} />
              <span>
              SysCondo - Sistema de Gestão de Condomínio
              </span>
          </div>   
            <span className='segment-text'>
            O RodInfo SysCondo automatiza o gerenciamento de maneira simples e organizada de um Condomínio Residencial ou Comercial.
            Uma forma eficaz de otimizar seus processos, garantir a qualidade dos processos e melhorar seus resultados.
            </span>

          <div className='buttons'>
            <button className='back-button' onClick={() => navigate('/')}>
              <span>
                página inicial
              </span>
              </button>
          </div> 

          </div>
          
        </div>
        <Footer />
    </div>
  )
}

export default Condominio