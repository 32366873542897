import { useState, useEffect } from "react";
import api from "../api/api";

export const useFetchClientes = (nome) => {

  const [cliente, setCliente] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/consulta/cliente/${nome}|${localStorage.getItem("suporte_usuario")}`);
        setCliente(response.data.dados);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (nome === '' || nome === null || nome === undefined) {
      nome = '*';
    }

    fetchClientes();
  }, []);

  return { cliente, loading, error };
};
