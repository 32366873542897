import { useState, useEffect } from "react";
import api from "../api/api";

export const useFetchChamadosMes = () => {

  const [chamado, setCategoria] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        setLoading(true);

        const response = await api.get(`/consulta/chamado/mes`);
        setCategoria(response.data.dados);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchClientes();
  }, []);

  return { chamado, error, loading };
};
