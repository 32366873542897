import 'bear-react-carousel/dist/index.css';

import BearCarousel, {BearSlideCard} from 'bear-react-carousel';

import sysHorti from '../../assets/sysHorti.png'

import frutas1 from '../../assets/frutas1.jpg'
import frutas2 from '../../assets/frutas2.jpg'

import engrenagem1 from '../../assets/engrenagem1.jpg'

import imovel1 from '../../assets/imovel1.jpg'

import laboratorio1 from '../../assets/laboratorio1.jpg'

import transportes1 from '../../assets/transportes1.jpg'



const images = [

    {id: 1, image: sysHorti, marginTop: "2rem"},
    {id: 2, image: frutas1},
    {id: 3, image: engrenagem1},
    {id: 4, image: frutas2},
    {id: 5, image: imovel1},
    {id: 6, image: laboratorio1},
    {id: 7, image: transportes1},

];


const Carousel = () => {

    const bearSlideItemData = images.map(item => {
        return {
            key: item.id,
            children: <BearSlideCard>
                <div style={{marginTop: item.marginTop, height: '100%', width: "100%", display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center", justifyItems: "center"}}>
                    <img src={item.image} alt=""  />
                </div>
            </BearSlideCard>
        };
    });
    return <BearCarousel
        // isEnableLoop
        isEnableAutoPlay
        autoPlayTime={2500}
        data={bearSlideItemData} 
        height="400px"
        isEnableNavButton
        style={{width: "100%"}}
        isEnablePagination
        className='carrosel'
        // isDebug
    />
}

export default Carousel