import React from 'react'
import './styles.css'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()

  return (
    <footer className='footer-container'>
        <span>RodInfo - Solução em Tecnologia da Informação - desde 1991</span>
        <span className='politica-link'><a onClick={()=>navigate('/politicas')}>Políticas e Privacidade</a></span>
    </footer>
  )
}

export default Footer