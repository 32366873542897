import { useNavigate } from "react-router-dom"

import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

import frutas from '../../../assets/frutas.png'

// import './styles.css'

import '../styles.css'

const Hortifruti = () => {
  const navigate = useNavigate()

  return (
    <div>
       <Header />  
        
        <div className='segment-page'>
          <div className="segment-content">
          <div className='segment-product-title'>
            <img src={frutas} />
              <span>
                SysHorti - Sistema de Gestão para Hortifruti
              </span>
          </div>   
            <span className='segment-text'>
            O RodInfo SysHorti automatiza o gerenciamento de produtos de Hortifruti de maneira simples e organizada de um BOX da CEASA, Barracão de Frutas, Centro de Distribuição e Varejão.
            Uma forma eficaz de otimizar seus processos, garantir a qualidade dos processos e melhorar seus resultados.
            </span>

          <div className='buttons'>
            <button className='back-button' onClick={() => navigate('/')}>
              <span>
                página inicial
              </span>
              </button>
          </div> 

          </div>
          
        </div>
        <Footer />
    </div>
  )
}

export default Hortifruti