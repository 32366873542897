import { useNavigate } from "react-router-dom"

import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'

import '../styles.css'

import casa from '../../../assets/casa.png'

const Imobiliaria = () => {
  const navigate = useNavigate()

  return (
    <div>
       <Header />  
        
        <div className='segment-page'>
          <div className="segment-content">
          <div className='segment-product-title'>
            <img src={casa} />
              <span>
                SysImobi - Sistema de Gestão Imobiliária
              </span>
          </div>   
            <span className='segment-text'>
            O RodInfo SysImobi automatiza o gerenciamento de maneira simples e organizada de uma Imobiliária de Locação de Imóveis.
            Uma forma eficaz de otimizar seus processos, garantir a qualidade dos processos e melhorar seus resultados.
            </span>

          <div className='buttons'>
            <button className='back-button' onClick={() => navigate('/')}>
              <span>
                página inicial
              </span>
              </button>
          </div> 

          </div>
          
        </div>
        <Footer />
    </div>
  )
};

export default Imobiliaria;
