import { useState, useEffect } from 'react';

import icones from '../../assets/icons/icones';

import { data_timestamp_imagem } from '../../utils/dataAtual'

import styles from './styles.module.css';

export default function UploadComponent({type, onImagesChange, resetTrigger}){

    const [files, setFiles] = useState([])
    const [imageDetails, setImagesDetails] = useState([])

    
    function handleChangeFileName(files){
        const renamedFiles = Array.from(files).map((file) => {
            const timestamp = data_timestamp_imagem(type);
            const newFileName = `${timestamp}_${file.name}`;
            return new File([file], newFileName, { type: file.type });
        });
        return renamedFiles;
    };

    function removeImage(e, index) {
        e.preventDefault();
        const newFileList = [...files];
        newFileList.splice(index, 1);
        setFiles(newFileList);
        setImagesDetails(newFileList);
        onImagesChange(newFileList); 
    }
    
    useEffect(() => {
        if (resetTrigger) {
            setFiles([]);
            setImagesDetails([]);
            onImagesChange([]);
        }
;
    }, [resetTrigger]);

    return (
        <>
           <label className={styles.uploadContainer}>
                <span>
                    <icones.GoFileMedia size={40} color="#0d6efd" />
                      <>
                        <p className='media-item-text'>Anexar imagens</p>
                        <p className='media-item-text'>CTRL + Click para selecionar várias.</p>
                      </>
                </span>
                <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => {
                        const renamedFiles = handleChangeFileName(e.target.files);
                        setFiles(renamedFiles);
                        setImagesDetails(renamedFiles);
                        onImagesChange(renamedFiles);
                    }}
                />
            </label>
            <article className={styles.fileItemContainer}>
                {Array.from(imageDetails).map((item, index) => (
                    <div key={index}>
                        <span className='file-item'>
                            <p className='file-item-name'>
                                <icones.FaRegFileImage size={20} color="#0d6efd" />
                                {item.name}
                            </p>
                            <button className={styles.removeFileBtn} onClick={(e) => removeImage(e, index)}>
                                <icones.AiOutlineClose size={20} />
                            </button>
                        </span>
                    </div>
                ))}
                
            </article>
        </>
    );
}
