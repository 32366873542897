import {useState, useRef} from 'react'
import { useNavigate } from "react-router-dom"

import {FaWhatsapp} from 'react-icons/fa'

import Header from "../../components/Header/Header"
import contact from '../../assets/contact-image.svg'


import './styles.css'
import Footer from '../../components/Footer/Footer'
import ReactWhatsapp from 'react-whatsapp'

const Contato = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')



  return (
    <div className='contact-page'>
      <Header />
      <div className="contact">
          <img src={contact} className="contact-image" />
      <div className="contact-centralize">
       <div className="contact-items">
          <span className="contact-text">
                você pode se conectar conosco a partir das seguintes formas: 
            </span>
            <span className="contact-forms">
                - contato via whatsapp, respondemos o mais rápido possível
            </span>
            <span className="contact-forms">
                - contate-nos via e-mail: <b>contato@rodinfo.com.br</b>
            </span>
            <span className="contact-forms">
                - através de uma ligação: <b>16 3242-3883</b> 
            </span>
       </div>
       <div className="contact-container">
        <div className="contact-form">
         <form className={error ? "input-error" : "inputs" }>
            <h3>Envie uma mensagem direta</h3>
             <textarea value={message} name='message' onChange={(e)=>setMessage(e.target.value)}/>   
            {error ? 
            <p style={{color: "red", fontSize: ".85rem"}}>{errorMessage}</p> : ''
          } 
            <ReactWhatsapp number='551632423883' message={message} className='send-email-btn' >
              <FaWhatsapp size={24} />
              enviar mensagem
            </ReactWhatsapp>
           </form>         
          </div>
       </div>
      </div>
      </div>
      <Footer />
     </div>

     
  )
}

export default Contato