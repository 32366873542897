import { useState, useEffect } from "react";
import api from "../api/api";

export const useFetchCategoria = (chave, codigo) => {

  const [categoria, setCategoria] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        setLoading(true);

        let params = {
            chav_chama: chave,
            codi_pesso: codigo
        }
        const response = await api.post(`/consulta/reincidencia`, params);
        setCategoria(response.data.categoria);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchClientes();
  }, [chave, codigo]);

  return { categoria, error, loading };
};
