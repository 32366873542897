import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import BaseLayout from "../suporte/restrito/layout/BaseLayout";
import icones from "../../assets/icons/icones";

import styles from './styles.module.css'

export default function Gabarito() {
    
    const [categoria, setCategoria] = useState('');
    const [titulo, setTitulo] = useState('');
    const [resolucoesFiltradas, setResolucoesFiltradas] = useState([]);

    const resolucoes = [
        { id: 1, titulo: 'Rejeição por ICMS', categoria: 'Pedido' },
        { id: 155, titulo: 'Venda sendo emitida duplicada em terminal', categoria: 'Venda' },
        { id: 17, titulo: 'Erro ao gerar SPED', categoria: 'SPED' },
        { id: 3, titulo: 'Investigando rejeição de boletos', categoria: 'Cobrança' },
        { id: 19, titulo: 'Buscando por XMLs', categoria: 'Outros' }
    ];

    useEffect(() => {
        setResolucoesFiltradas(resolucoes);
    }, []);

    function buscarResolucaoFiltrada(e) {
        e.preventDefault();

        const filtradas = resolucoes.filter(resol =>
            resol.titulo.toLowerCase().includes(titulo.toLowerCase()) &&
            (categoria === '' || resol.categoria === categoria)
        );
        setResolucoesFiltradas(filtradas);
    }

    return (
        <BaseLayout>
            <main className={styles.mainContent}>
                <h2>Resoluções</h2>

                <div className={styles.filtros}>
                    <input
                        placeholder="Buscar resoluções por título"
                        type="search"
                        className="input"
                        value={titulo}
                        onChange={(e) => setTitulo(e.target.value)}
                    />
                    <select
                        placeholder="Buscar resoluções por categoria"
                        className="input"
                        value={categoria}
                        onChange={(e) => setCategoria(e.target.value)}
                    >
                        <option value="">Todas</option>
                        <option value="Pedido">Pedido</option>
                        <option value="Venda">Venda</option>
                        <option value="Outros">Outros</option>
                        <option value="Cobrança">Cobrança</option>
                    </select>

                    <button
                        className={styles.btnBusca}
                        onClick={buscarResolucaoFiltrada}
                    >
                        <icones.FiSearch size={24} color="#FFF" />
                    </button>
                </div>

                <section className={styles.content}>
                    {resolucoesFiltradas.map((resol) =>
                        <div key={resol.id} className={styles.resolContent}>
                            <Link 
                               to={`/restrito/detalhes-resolucao/${resol.id}`}
                               style={{textDecoration: 'none', color: 'inherit'}}
                             >
                                <div className={styles.resolCategoria}>
                                    <strong>{resol.categoria}</strong>
                                </div>
                                <div className={styles.resolInfos}>
                                    <span>
                                        <strong>{resol.id} -</strong>
                                    </span>
                                    <span>
                                        <strong>{resol.titulo}</strong>
                                    </span>
                                </div>
                            </Link>
                        </div>
                    )}
                </section>
            </main>
        </BaseLayout>
    );
}