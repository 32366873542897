
import Menu from '../components/Menu'
import './baseLayout.css'

export default function BaseLayout({ children }){


    return (
        <main className="baseLayout">
            
            <Menu />

           <div className="children-container">
            {children}
           </div>

        </main>
    )
}