import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import api from '../../api/api'

import BaseLayout from "../suporte/restrito/layout/BaseLayout"

import styles from './styles.module.css'

export default function DetalhesResolucao(){
  
    const { id } = useParams()
    const [imagens, setImagens] = useState([]);

    useEffect(()=>{

      async function carregarDetalhes(){
        const response = await api.get(`/detalhe/chamado/${id}|${localStorage.getItem("suporte_usuario")}`)
        setImagens(response.data.dados);
      }
      carregarDetalhes();

    },[])

    return (
       <BaseLayout>
         <main className={styles.content}>
            <h2>Resolução: {id}</h2>

            <section>
              <h3>Detalhes</h3>
              {imagens.map((item, index) => (
              <img
                key={index}
                src={item.foto_resol}
                alt={`Imagem ${index}`}
                style={{ width: '400px', height: '400px' }}
              />
            ))}
            </section>
         </main>
       </BaseLayout>
    )
}